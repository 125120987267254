import Vue from "vue";
import Vuex from "vuex";

import terminal from './modules/terminal';
import productCatalog from './modules/productCatalog';
import serviceRequest from './modules/serviceRequest';

import deviceRequest from './modules/deviceRequest';
import serviceResponse from './modules/serviceResponse';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
      terminal,
      productCatalog,
      serviceRequest,
      deviceRequest,
      serviceResponse,
  }
});
