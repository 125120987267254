import namespace from '@/utils/namespace';

export default namespace('serviceRequest', {
	getters: [
		'IS_SERVICE_REQUEST_ACTIVE',
	],
	actions: [
		'TOGGLE_SERVICE_REQUEST_ACTIVE',
	],
	mutations: [
		'TOGGLE_SERVICE_REQUEST_ACTIVE',
	],
});
