import namespace from "@/utils/namespace";

export default namespace("productCatalog", {
  getters: ["PRODUCT_CATALOG", "IS_MENU_FETCHED"],
  actions: [
    "FETCH_PRODUCT_CATALOG",
    "PRINT",
    "SEND_INFO_TO_API",
  ],
  mutations: [
    "RECEIVE_PRODUCT_CATALOG",
    "RECEIVE_PRODUCT_CATALOG_SUCCESS",
    "RECEIVE_PRODUCT_CATALOG_FAILED",
    "PRINT",
    "SET_INFO",
  ],
});
