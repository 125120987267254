//config file

/*
usage:
import { default as getConfigValue } from '@/utils/config.js';

var value = getConfigValue("KEY");

*/

export default function getValue(key) {
  //return the value of key in dictionary config

  var config = {
    _apiBaseUrl: "http://10.22.34.21:42787/", //base URL to the Backend Server. Needs to end with '/'
    apiBaseUrl: "https://webterminal.api.icp-ts.de/",
    printerUrl: "Browser.print",
    scannerUrl: null, 
    showKeyboard: false,
    landingPage : {
      register_text: "Sehr geehrte Kundin, sehr geehrter Kunde,<br/><br/>  zur Freischaltung ihres Webterminals für den Verkauf von Prepaid-Produkten ist ein gültiger Prepaidvertrag erforderlich. Bitte wenden Sie sich diesbezüglich an die angegebene Mailadresse:", 
      register_mail: "technical-support@digitalreload.com"
    },
    loginRequired: true,
    keycloakConfig: {
      url: "https://auth.api.icp-ts.de",
      realm: "webterminal_live",
      clientId: "webterminal-2FA-live",
    }
  };

  return config[key];
}
