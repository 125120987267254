import Vue from "vue";
import { sync } from "vuex-router-sync";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

import VModal from "vue-js-modal";

import App from "@/components/app/";
import sendInfoData from "@/services/sendInfoData"

import "./axios";

import router from "./router";
import store from "./store";

import { default as getConfigValue } from "@/utils/config.js";
const socketIoUrl = getConfigValue("apiBaseUrl");
const loginRequired = getConfigValue("loginRequired") === true;

Vue.use(VModal);

const socket = io(socketIoUrl, { 
  autoConnect: !loginRequired,
});
//if not autoConnected: connect happens in onMount of component "app.vue"
Vue.use(VueSocketIOExt, socket, { store });

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, instance, info) => {
  sendInfoData({
    issuer: "errorHandler",
    data: "|err: "+err+" instance: "+instance+" info: "+info+"|"
  })
}
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
sync(store, router);

const app = new Vue({
  store,
  router,
  render: (h) => h(App),
});

export { app, router, store };
