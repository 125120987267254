import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import { keycloakAdapter } from '@/main.js';
import { default as getConfigValue } from "@/utils/config.js";

Vue.use(VueAxios, Axios);

window.$axios = Axios;
export default Axios;


export function getAxiosConfig() {
  let axiosConfig = {}
  if (getConfigValue("loginRequired")) {
      axiosConfig = {
      headers : {
          Authorization: `Bearer ${keycloakAdapter.token}`
      }
    }
  }
  return axiosConfig;
}