/* eslint-disable no-param-reassign, no-shadow */

import types from "./types";

import { default as verifyTerminalId } from "@/services/verifyTerminalId.js";
import { default as sendUrlError } from "@/services/sendUrlError.js";
import { default as sendInfoData } from "@/services/sendInfoData.js";

const state = {
  isError: false,
  isVerified: false,
  isVerifying: false,
  isFetching: false,
  errorMsg: null,
  vtcTerminalId: null,
  vtcPort: null,
  vtcHost: null,
};

const getters = {
  [types.getters.IS_TERMINAL_ID_VERIFIED]: (state) => state.isVerified,
};

const actions = {
  [types.actions.VERIFY_TERMINAL_ID]: (ctx, lottoId) => {
    ctx.commit(types.mutations.VERIFY_TERMINAL_ID);
    return verifyTerminalId(lottoId).then(
      (response) => {
        const r = response.data.result;
        const match = r.trim().match('^OK [0-9]{8}'); // "OK <TID>..."
        if (match) {
          const data = {
            vtcTerminalId : r.slice(3,11),
            lastReport : parseInt(r.slice(12)),
            vtcPort : response.data.vtcPort,
            vtcHost : response.data.vtcHost,
          }
          ctx.commit(types.mutations.VERIFY_TERMINAL_ID_SUCCESS, data);
        } else {
          ctx.commit(types.mutations.VERIFY_TERMINAL_ID_FAILED,  "ID NOT VALID");
        }
      },
      (error) => {
        ctx.commit(types.mutations.VERIFY_TERMINAL_ID_FAILED, error);
      }
    );
  },

  [types.actions.SEND_URL_ERROR_TO_API]: (ctx, data) => {
    return sendUrlError(data);
  },

  [types.actions.SEND_INFO_TO_API]: (ctx, data) => {
    return sendInfoData(data);
  },
};

const mutations = {
  [types.mutations.VERIFY_TERMINAL_ID]: (state) => {
    state.isVerifying = true;
  },

  [types.mutations.VERIFY_TERMINAL_ID_SUCCESS]: (state, data) => {
    state.isVerifying = false;
    state.isError = false;
    state.isVerified = true;     
    state.vtcTerminalId = data.vtcTerminalId
    state.lastReport = data.lastReport
    state.vtcPort = data.vtcPort;
    state.vtcHost = data.vtcHost;
  },

  [types.mutations.VERIFY_TERMINAL_ID_FAILED]: (state, error) => {
    state.isVerifying = false;
    state.isVerified = false;
    state.isError = true;
    state.errorMsg = error;
  },
};

export default { state, getters, actions, mutations };
