import axios, { getAxiosConfig } from "@/axios";

import { default as getConfigValue } from "@/utils/config.js";
const apiBaseUrl = getConfigValue("apiBaseUrl");

export default async function verifyTerminalId(terminalId) {
  const axiosConfig = getAxiosConfig()

  return await axios
    .post(apiBaseUrl + "api/rest/v1/verifyTerminalId", {
      terminalId: terminalId,
    }, axiosConfig)
    .then((r) => Promise.resolve(r))
    .catch((e) => Promise.reject(e));
}
