/* eslint-disable no-param-reassign, no-shadow, no-unused-vars */

const state = {
	data: {},
};

const actions = {
	socket_serviceResponse({ dispatch, commit }, message) {	
	},
};

const mutations = {
	SOCKET_SERVICERESPONSE(state, data) {
		state.data = data;
	},
};

export default { state, actions, mutations };
