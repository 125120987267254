/* eslint-disable no-param-reassign, no-shadow, no-unused-vars */

const state = {
  data: {},
  isWaiting: false,
};

const actions = {
  socket_showTextDialog({ dispatch, commit }, message) {},
  socket_showGetAmountDialog({ dispatch, commit }, message) {},
  socket_showGetAnyKeyDialog({ dispatch, commit }, message) {},
  socket_showGetCharDialog({ dispatch, commit }, message) {},
  socket_showGetConfirmationDialog({ dispatch, commit }, message) {},
  socket_showGetDecimalsDialog({ dispatch, commit }, message) {},
  socket_showGetMenuDialog({ dispatch, commit }, message) {},
  socket_showGetDateDialog({ dispatch, commit }, message) {},
  socket_printTicket({ dispatch, commit }, message) {},
  SET_IS_WAITING({ dispatch, commit }) {
    commit("SET_IS_WAITING");
  },
  RESET_IS_WAITING({ dispatch, commit }) {
    commit("RESET_IS_WAITING");
  },
};

const mutations = {
  SOCKET_SHOWTEXTDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETAMOUNTDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETANYKEYDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETCHARDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETCONFIRMATIONDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETDECIMALSDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETMENUDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_SHOWGETDATEDIALOG(state, data) {
    state.isWaiting = false;
    state.data = data;
  },
  SOCKET_PRINTTICKET(state, data) {
    state.isWaiting = true;
    state.data = data;
  },
  SET_IS_WAITING(state) {
    state.isWaiting = true;
  },
  RESET_IS_WAITING(state) {
    state.isWaiting = false;
  },
};

export default { state, actions, mutations };
