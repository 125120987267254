import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
const Verify = () => import("@/views/verify");
const ProductCatalog = () => import("@/views/productCatalog");
const Register = () => import("@/views/register");

Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'ProductCatalog',
			component: ProductCatalog,
			beforeEnter: (to, from, next) => {
				if(store.state.terminal.isVerified) {
					next();
				} else {
					next({
						name: 'Verify',
						query: {
							LottoID: to.query.LottoID,
						}
					});
				}
			}
		},
		{
			path: '/verify',
			name: 'Verify',
			component: Verify,
		},
		{
			path: '/register',
			name: 'Register',
			component: Register,
		},
	]
});
