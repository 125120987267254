/* eslint-disable */
import { app } from './app';
import { default as getConfigValue } from "@/utils/config.js"

import Keycloak from 'keycloak-js';

const keycloakConfig = getConfigValue("keycloakConfig")
export const keycloakAdapter = new Keycloak(keycloakConfig);

try {
  if(getConfigValue("loginRequired")) {
    keycloakAdapter.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    }).then(authenticated => {
      console.log("authenticated = "+authenticated)
      if (authenticated) {
        app.$mount('#app');
      } else {
        //TODO Error-Page
        console.log("not authenticated")
      }
    });
  } else {
    app.$mount('#app');
  }
} catch (error) {
  console.log("Error: "+error)
}