import namespace from "@/utils/namespace";

export default namespace("terminal", {
  getters: ["IS_TERMINAL_ID_VERIFIED"],
  actions: [
    "VERIFY_TERMINAL_ID",
    "FETCH_TERMINAL_ID",
    "SEND_URL_ERROR_TO_API",
    "SEND_INFO_TO_API",
  ],
  mutations: [
    "VERIFY_TERMINAL_ID",
    "VERIFY_TERMINAL_ID_SUCCESS",
    "VERIFY_TERMINAL_ID_FAILED",
    "FETCH_TERMINAL_ID",
    "FETCH_VTC_TERMINAL_ID_SUCCESS",
    "FETCH_VTC_TERMINAL_ID_FAILED",
  ],
});
