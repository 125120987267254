import types from './types';

const state = {
	isServiceRequestActive: false,

};

const getters = {
	[types.getters.IS_SERVICE_REQUEST_ACTIVE]: state => state.serviceRequestActive,
};

const actions = {
	[types.actions.TOGGLE_SERVICE_REQUEST_ACTIVE]: (ctx) =>
	{
		ctx.commit(types.mutations.TOGGLE_SERVICE_REQUEST_ACTIVE);	
	},
};

const mutations = {

	[types.mutations.TOGGLE_SERVICE_REQUEST_ACTIVE]: (state) =>
	{
		state.isServiceRequestActive = !state.isServiceRequestActive;
	},
};

export default { state, getters, actions, mutations };
